body {
    font-family: 'EB Garamond', serif;
    font-size: 20px;
    margin: 0;
    padding: 0;

    --color-accent: #D291BC;
    --color-accent-dark: #957DAD;
    --color-accent-light: #E0BBE4;
    --color-base: #FFF;
    --color-gray: #777;
    --color-lightgray: #F0F0F0;
    --color-invert: #000;
    --color-shadow: rgba(0, 0, 0, 0.5);

    @media (prefers-color-scheme: dark) {
        & {
            background: #000;
            color: #FFF;

            --color-accent-dark: #BD82A9;
            --color-accent-light: #F0A5D7;
            --color-base: #000;
            --color-invert: #FFF;
            --color-gray: #CCC;
            --color-lightgray: #444;
            --color-shadow: rgba(255, 255, 255, 0.5);
        }
    }
}

#container {
    box-sizing: border-box;
    max-width: 36em;
    min-height: 100vh;
    margin: 0 auto;
    padding: 1em;
}

#site-header {
    margin-left: -1em;
    padding-left: 1em;
    margin-right: -1em;
    border-bottom: 1px solid var(--color-gray);
    display: flex;
    flex-wrap: wrap;

    h1 {
        flex: 1 0;
        font-size: 2.5em;
        font-weight: normal;
        line-height: 1;
        margin-top: 0;
        margin-bottom: 1rem;
        padding-right: 1em;

        span {
            color: var(--color-accent);
        }

        a:link, a:visited, a:active {
            text-decoration: none;
            color: inherit;
        }

        a:hover {
            text-decoration: underline;
            text-decoration-color: var(--color-accent);
            color: inherit;
        }
    }

    nav {
        margin-bottom: 1rem;
        align-self: flex-end;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                padding: 0 0.25em;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
}

article {
    h3, h4, h5, h6 {
        font-style: italic;

        em {
            font-style: normal;
        }
    }
}

.footnotes {
    border-top: 1px solid var(--color-gray);
    margin-top: 3em;
}

#site-footer {
    color: var(--color-gray);
    margin-top: 2em;
    font-style: italic;
    margin-left: -1em;
    padding-left: 1em;
    margin-right: -1em;
    border-top: 1px solid var(--color-gray);

    p {
        text-align: center;
    }
}

// General Styles

// CSS lock: https://fvsch.com/code/css-locks

@media screen and (min-width: 1024px) and (max-width: 1920px) {
    body {
        font-size: calc(20px + 6 * (100vw - 1024px) / (1920 - 1024));
    }
}

@media screen and (min-width: 1920px) {
    body {
        font-size: 26px;
    }
}

h1, h2, h3 {
    font-weight: normal;
}

a:link {
    color: var(--color-invert);
}

a:visited {
    color: var(--color-accent-dark);
}

a:hover {
    color: var(--color-accent);
    text-decoration-color: var(--color-accent);
}

ul {
}

ul, ol {
    padding-left: 1em;
}

li p:first-child {
    margin-top: 0;
}

hr {
    border: 1px solid var(--color-gray);
    border-bottom: 0;
}

p {
    hyphens: auto;
    text-align: justify;
}

details {
    margin: 1em 0;
}

time {
    font-style: italic;
    color: var(--color-gray);
}

.gray {
    color: var(--color-gray);
}

.no-justify {
    p {
        text-align: left !important;
    }
}

*[lang="zh-CN"] {
    font-family: "Noto Serif SC", "Noto Serif JP", "EB Garamond", serif;
    font-size: 0.95em;
    font-style: normal;
}

*[lang="zh-TW"] {
    font-family: "Noto Serif TC", "Noto Serif JP", "EB Garamond", serif;
    font-size: 0.95em;
    font-style: normal;
}

*[lang="ja-JP"] {
    font-family: "Noto Serif JP", "Noto Serif SC", "EB Garamond", serif;
    font-size: 0.95em;
    font-style: normal;
}

*[lang="ko-KR"] {
    font-size: 0.95em;
    font-style: normal;
}

.music-list ul {
    list-style-type: none;
    color: var(--color-gray);
}

.lyrics {
    white-space: pre-line;
}

.lyrics.romaji {
    font-style: italic;
    white-space: pre-line;
}

.cover-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    img {
        flex: 1 0 auto;
        flex-basis: 30%;
        max-width: 50%;
        min-width: 200px;
    }

    &.small img {
        max-width: 20%;
        min-width: 100px;
    }
}

.shadow img, img.shadow {
    box-shadow: 2px 2px 4px var(--color-shadow);
}

.tag:not(:last-child):after {
    content: ",";
}

figure {
    margin: 1em 0;

    img {
        width: 100%;
        margin: 0 auto;
        display: block;
    }

    figcaption {
        text-align: center;
        font-style: italic;

        em {
            font-style: normal;
        }
    }
}

@media (min-width: 60em) {
    figure {
        margin: 1em -5em;

        figcaption {
            margin-left: 6em;
            margin-right: 6em;
        }

        &.tall {
            margin: 1em 1em;

            img {
                width: 75%;
            }
        }
    }
}

blockquote {
    border-left: 0.25em solid var(--color-accent-dark);
    margin-left: -1.25em;
    margin-right: 0;
    padding-left: 1em;
    color: var(--color-gray);
}

ruby {
    rt {
        margin-bottom: -0.3em;
    }
}

/* Special Styles (Page Specific) */

.manga-recommendation {
    align-items: center;
    display: flex;
    margin: 0 auto;
    width: 80%;

    img {
        width: 40%;
    }

    ul {
        margin: 0 0 0 1em;
    }

    @media screen and (max-width: 767px) and (orientation: portrait) {
        display: block;
        width: 100%;

        img {
            display: block;
            margin: 0 auto;
            width: 80%;
        }
    }
}

.niichi {
    color: #309ac4;
}

.shimazaki {
    color: #ef5e59;
}

.homepage-toc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0 auto;
    padding: 0;

    li {
        flex: 1 0 auto;
        line-height: 1.25em;
        text-align: center;
    }
}

table {
    border-collapse: collapse;
    margin: 1em 0;
    width: 100%;

    thead tr {
        border-bottom: 1px solid var(--color-invert);
    }

    th {
        text-align: left;
    }

    th, td {
        padding: 0 0.25em;
    }

    th.align-right, td.align-right {
        text-align: right;
    }

    tr {
        transition: 0.1s background-color ease-out;
    }

    tr:nth-child(2n) {
        background: var(--color-lightgray);
    }

    tbody tr:hover {
        background: var(--color-accent-light);
    }
}

tt, code, pre {
    font-family: 'JetBrains Mono', monospace;
    font-size: 0.85em;
}

// Used on "Roll Your Own Spotify Wrapped"
.output div.highlight {
    background: var(--color-invert);
    border: 1px solid var(--color-base);
}
.output pre {
    background: transparent;
}

.quote-pull {
    margin-left: -0.4em;
}

// Used on lists

section.recommendations-covers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
        display: block;
        flex: 0 0 9em;
        height: 12em;
        position: relative;

        img {
            border: 1px solid var(--color-invert);
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            transition: all 0.1s ease-in-out;
            width: 100%;
        }

        &:hover {
            img {
                box-shadow: 0px 0px 4px var(--color-shadow);
                z-index: 99999;
            }
        }
    }

    @media screen and (max-width: 767px) {
        a {
            flex: 0 0 5em;
            height: 7.5em;
        }
    }

    @media screen and (min-width: 1024px) {
        & {
            margin-left: -10em;
            margin-right: -10em;
        }
    }
}

section.recommendations-list {
    h3 {
        margin: 1rem 0 0.5em 0;
    }

    .metadata {
        align-items: center;
        display: flex;
        margin: 0 auto;
        width: 100%;

        img {
            flex: 0 0 12em;
            margin-right: 1em;
            width: 12em;
        }

        div {
            flex: 1 0;
        }

        p {
            text-align: left;
        }

        @media screen and (max-width: 767px) and (orientation: portrait) {
            display: block;
            width: 100%;

            img {
                display: block;
                margin: 0 auto;
                width: 80%;
            }
        }
    }

    .people {
        font-style: italic;
        margin-top: 0;
        margin-bottom: 0;

        a, .person {
            font-style: normal;
        }
    }
}

// Pygments rainbow_dash theme

pre { line-height: 125%; margin: 0; }
td.linenos pre { color: #000000; background-color: #f0f0f0; padding-left: 5px; padding-right: 5px; }
span.linenos { color: #000000; background-color: #f0f0f0; padding-left: 5px; padding-right: 5px; }
td.linenos pre.special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
span.linenos.special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
.highlight .hll { background-color: #ffffcc }
.highlight {
    background: #efefef;
    color: #000;
    overflow-x: auto;
    padding: 0.25em 0.5em;
}
.highlight .c { color: #0080ff; font-style: italic } /* Comment */
.highlight .err { color: #ffffff; background-color: #cc0000 } /* Error */
.highlight .k { color: #2c5dcd; font-weight: bold } /* Keyword */
.highlight .o { color: #2c5dcd } /* Operator */
.highlight .ch { color: #0080ff; font-style: italic } /* Comment.Hashbang */
.highlight .cm { color: #0080ff; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: #0080ff } /* Comment.Preproc */
.highlight .cpf { color: #0080ff; font-style: italic } /* Comment.PreprocFile */
.highlight .c1 { color: #0080ff; font-style: italic } /* Comment.Single */
.highlight .cs { color: #0080ff; font-weight: bold; font-style: italic } /* Comment.Special */
.highlight .gd { background-color: #ffcccc; border: 1px solid #c5060b } /* Generic.Deleted */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gr { color: #ff0000 } /* Generic.Error */
.highlight .gh { color: #2c5dcd; font-weight: bold } /* Generic.Heading */
.highlight .gi { background-color: #ccffcc; border: 1px solid #00cc00 } /* Generic.Inserted */
.highlight .go { color: #aaaaaa } /* Generic.Output */
.highlight .gp { color: #2c5dcd; font-weight: bold } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #2c5dcd; font-weight: bold } /* Generic.Subheading */
.highlight .gt { color: #c5060b } /* Generic.Traceback */
.highlight .kc { color: #2c5dcd; font-weight: bold } /* Keyword.Constant */
.highlight .kd { color: #2c5dcd; font-weight: bold } /* Keyword.Declaration */
.highlight .kn { color: #2c5dcd; font-weight: bold } /* Keyword.Namespace */
.highlight .kp { color: #2c5dcd } /* Keyword.Pseudo */
.highlight .kr { color: #2c5dcd; font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: #5918bb; font-weight: bold } /* Keyword.Type */
.highlight .m { color: #5918bb; font-weight: bold } /* Literal.Number */
.highlight .s { color: #00cc66 } /* Literal.String */
.highlight .na { color: #2c5dcd; font-style: italic } /* Name.Attribute */
.highlight .nb { color: #5918bb; font-weight: bold } /* Name.Builtin */
.highlight .nc { text-decoration: underline } /* Name.Class */
.highlight .no { color: #318495 } /* Name.Constant */
.highlight .nd { color: #ff8000; font-weight: bold } /* Name.Decorator */
.highlight .ni { color: #5918bb; font-weight: bold } /* Name.Entity */
.highlight .ne { color: #5918bb; font-weight: bold } /* Name.Exception */
.highlight .nf { color: #ff8000; font-weight: bold } /* Name.Function */
.highlight .nt { color: #2c5dcd; font-weight: bold } /* Name.Tag */
.highlight .ow { color: #2c5dcd; font-weight: bold } /* Operator.Word */
.highlight .w { color: #cbcbcb } /* Text.Whitespace */
.highlight .mb { color: #5918bb; font-weight: bold } /* Literal.Number.Bin */
.highlight .mf { color: #5918bb; font-weight: bold } /* Literal.Number.Float */
.highlight .mh { color: #5918bb; font-weight: bold } /* Literal.Number.Hex */
.highlight .mi { color: #5918bb; font-weight: bold } /* Literal.Number.Integer */
.highlight .mo { color: #5918bb; font-weight: bold } /* Literal.Number.Oct */
.highlight .sa { color: #00cc66 } /* Literal.String.Affix */
.highlight .sb { color: #00cc66 } /* Literal.String.Backtick */
.highlight .sc { color: #00cc66 } /* Literal.String.Char */
.highlight .dl { color: #00cc66 } /* Literal.String.Delimiter */
.highlight .sd { color: #00cc66; font-style: italic } /* Literal.String.Doc */
.highlight .s2 { color: #00cc66 } /* Literal.String.Double */
.highlight .se { color: #c5060b; font-weight: bold } /* Literal.String.Escape */
.highlight .sh { color: #00cc66 } /* Literal.String.Heredoc */
.highlight .si { color: #00cc66 } /* Literal.String.Interpol */
.highlight .sx { color: #318495 } /* Literal.String.Other */
.highlight .sr { color: #00cc66 } /* Literal.String.Regex */
.highlight .s1 { color: #00cc66 } /* Literal.String.Single */
.highlight .ss { color: #c5060b; font-weight: bold } /* Literal.String.Symbol */
.highlight .bp { color: #5918bb; font-weight: bold } /* Name.Builtin.Pseudo */
.highlight .fm { color: #ff8000; font-weight: bold } /* Name.Function.Magic */
.highlight .il { color: #5918bb; font-weight: bold } /* Literal.Number.Integer.Long */
